var globalMixin = {
    data () {
        return {
            mixinVariable: [
                'aa', 'bb', 'cc'
            ],
            quantities: [
                {text: '1 Pax',id: '1'},
                {text: '2 Pax',id: '2'},
                {text: '3 Pax',id: '3'},
                {text: '4 Pax',id: '4'},
                {text: '5 Pax',id: '5'},
                {text: '6 Pax',id: '6'}
            ],
            features: [
                {
                    name: 'Private Transfer',
                    slug: 'point-to-point',
                    icon: "/icon-form-ptp.png",
                    route: '/point-to-point',
                },
                {
                    name: "Disposal",
                    slug: "disposal",
                    icon: "/icon-form-disposal.png",
                    route: '/disposal',
                },
                {
                    name: "SIC Transfer",
                    slug: "seat-in-coach",
                    icon: "/icon-form-sic.png",
                    route: '/seat-in-coach',
                },
                {
                    name: "Express Bus Transfer",
                    slug: "express",
                    icon: "/icon-form-express.png",
                    route: "/express",
                },
                {
                    name: "Tour Transfer",
                    slug: "tour-transfer",
                    icon: "/icon-form-packages.png",
                    route: "/packages",
                }
            ],
            cities: {},
            countryDepartSgAndMy: [],
            countryReturnSgAndMy: [],
            departSgAndMy: [],
            returnSgAndMy: [],
            hours: [
                {text: '1 Hours',id: '1'},
                {text: '2 Hours',id: '2'},
                {text: '3 Hours',id: '3'},
                {text: '4 Hours',id: '4'},
                {text: '5 Hours',id: '5'},
                {text: '6 Hours',id: '6'},
                {text: '7 Hours',id: '7'},
                {text: '8 Hours',id: '8'}
            ],
            hoursFrom3: [
                {text: '3 Hours',id: '3'},
                {text: '4 Hours',id: '4'},
                {text: '5 Hours',id: '5'},
                {text: '6 Hours',id: '6'},
                {text: '7 Hours',id: '7'},
                {text: '8 Hours',id: '8'}
            ],
            hoursFrom4: [
                {text: '4 Hours',id: '4'},
                {text: '5 Hours',id: '5'},
                {text: '6 Hours',id: '6'},
                {text: '7 Hours',id: '7'},
                {text: '8 Hours',id: '8'}
            ],
            titles: [
                {text: 'Mr',id: 'Mr'},
                {text: 'Mrs',id: 'Mrs'},
                {text: 'Ms',id: 'Ms'},
                {text: 'Dr',id: 'Dr'}
            ],
            times: [],
            timesFrom3: [],
            timesFrom4: [],
            phoneCode: [],
            phoneCodeSelect2: [],
        }
    },
    methods: {
        async getCity () {
            var payload = {
                combo: 1, 
                transport2: 1,
                group: 1,
            }
            var res = await this.$travelApi.get('/api/travel/v1/city', { params: payload })
            this.cities = res
        },
        async getCountrySgAndMyDepart () {
            var payload = {
                type: 'sgptomy',
                system: '3b27e578-0cd7-11ea-a825-d2118390b116'
            }
            var res = await this.$travelApi.get('/api/travel/v1/item/transport/countrydepartsgtomy', { params: payload })
            this.countryDepartSgAndMy = res
        },
        async getCountrySgAndMyReturn (country) {
            var payload = {
                type: 'sgptomy',
                system: '3b27e578-0cd7-11ea-a825-d2118390b116',
                country
            }
            var res = await this.$travelApi.get('/api/travel/v1/item/transport/countryreturnsgtomy', { params: payload })
            this.countryReturnSgAndMy = res
        },
        async getSgAndMyDepart (country) {
            var payload = {
                type: 'sgptomy',
                system: '3b27e578-0cd7-11ea-a825-d2118390b116',
                country
            }
            var res = await this.$travelApi.get('/api/travel/v1/item/transport/departsgtomy', { params: payload })
            this.departSgAndMy = res
        },
        async getSgAndMyReturn (depart, country) {
            var payload = {
                type: 'sgptomy',
                system: '3b27e578-0cd7-11ea-a825-d2118390b116',
                depart,
                country,
                category: 'new'
            }
            var res = await this.$travelApi.get('/api/travel/v1/item/transport/returnsgtomy', { params: payload })
            this.returnSgAndMy = res
        },
        async getTimes () {
            this.times = []
            this.timesFrom3 = []
            this.timesFrom4 = []
            for (let hour = 0; hour < 24; hour++) {
                for (let minute = 0; minute < 60; minute+=30) {
                    let textHour = hour >= 10 ? `${hour}` : `0${hour}`;
                    let textMinute = minute >= 10 ? `${minute}` : `0${minute}`;
                    
                    this.times.push({
                        text: `${textHour}:${textMinute}`,
                        id: `${textHour}:${textMinute}`
                    }); 

                    if (hour >= 3) {
                        this.timesFrom3.push({
                            text: `${textHour}:${textMinute}`,
                            id: `${textHour}:${textMinute}`
                        }); 
                    }

                    if (hour >= 4) {
                        this.timesFrom4.push({
                            text: `${textHour}:${textMinute}`,
                            id: `${textHour}:${textMinute}`
                        }); 
                    }
                }
            }
        },
        async getPhoneCall () {
            var payload = {}
            var res = await this.$travelApi.get('/api/travel/v2/country', { params: payload })
            this.phoneCode = res

            this.phoneCode = this.phoneCode.filter(a =>  a.PhoneCode != null && a.PhoneCode != '');

            this.phoneCodeSelect2 = [];
            for(let i=0; i < this.phoneCode.length; i++){
                var text = `+${this.phoneCode[i].PhoneCode} ${this.phoneCode[i].Name}`;
                this.phoneCodeSelect2.push({
                    onlyName: this.phoneCode[i].Name || "",
                    onlyCode: `+${this.phoneCode[i].PhoneCode}` || "",
                    onlyId: this.phoneCode[i].Oid || "",
                    name: text  || "",
                    slug: text  || "",
                    id: text || "",
                });
            }

            this.phoneCodeSelect2 = this.phoneCodeSelect2.sort((a, b) => {
                if(a.onlyName < b.onlyName) return -1
                if(a.onlyName > b.onlyName) return 1
                return 0
            })
        }
    },
    mounted () {
        this.getCity()
        this.getCountrySgAndMyDepart()
        this.getTimes()
        this.getPhoneCall()
    }
}

export default globalMixin