
import { createI18n } from 'vue-i18n'
// User defined lang
import enLocale from './en'
import idLocale from './id'
import zhLocale from './zh'
import twnLocale from './twn'

const messages = {
  USA: {
    ...enLocale
  },
  IDN: {
    ...idLocale
  },
  CHN: {
    ...zhLocale
  },
  HKG: {
    ...twnLocale
  },
}

const getLocale = () => {
  // if (!localStorage.getItem('access_token')) localStorage.setItem('codeLanguage', 'USA');
  
  const cookieLanguage = localStorage.getItem('codeLanguage') ?? 'USA'

  // if (cookieLanguage) {
  //   return cookieLanguage
  // }
  
  // const language = navigator.language.toLowerCase()

  const locales = Object.keys(messages)
  
  for (const locale of locales) {
    if (cookieLanguage.indexOf(locale) > -1) {
      return locale
    }
  }

  localStorage.setItem('language', 'English')
  // localStorage.setItem('codeLanguage', 'USA');
  return 'USA'
}

const i18n = createI18n({
  locale: getLocale(),
  messages
})

// export default i18n
export default (App) => {
  App.use(i18n)
}
