<template>
  <div>
    <label class="mobile:col-span-12 mobile:mb-2">{{ label }}</label>
    
    <select 
      v-model="localValue" 
      @change="handleChange($event)"
      :searchable="true"
      class="w-full border border-black text-black placeholder-black rounded-md block py-1 px-2 bg-transparent mobile:col-span-12 mobile:text-base mobile:p-1 mobile:rounded-md">
        <option 
          v-for="(option, index) in options" 
          :key="index" 
          :value="option.value">
          {{ option?.text }}
        </option>
    </select>
  </div>
</template>

<script>
export default {
  name: 'SelectComponent',
  props: {
    modelValue: {
      type   : [String, Number],
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    options: {
      type: Array,
      required: true
    }
  },

  emits: ['update:modelValue'],

  computed: {
    localValue: {
      get () {
        return this.modelValue
      },
      set (value) {
        this.$emit('update:modelValue', value)
      },
    },
  },

  methods: {
    handleChange (event) {
      this.$emit('update:modelValue', event.target.value)
    }
  }
}
</script>