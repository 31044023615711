import moment from 'moment';
import { marked } from 'marked';
var globalFunction = {
    methods: {
        formatDate(date, format="dddd, D MMMM YYYY"){
            if (date) {
                return moment(date.replace(/-/g, "/")).format(format);
            }
            return '-';
        },
        changeToMarked(string="") {
            return marked(string || "");
        }
    },
}

export default globalFunction