<template>
  <div>
    <p class="text-sm">{{ label }}</p>
    <div class="relative tablet:mb-3 mobile:mb-3">
      <input 
        v-model="localValue"
        :type="type" 
        :placeholder="placeholder"
        class="bg-transparent form-input border-solid border-black border-thin py-1 px-2 placeholder-black text-black appearance-none w-full block focus:outline-none rounded-md">
    </div>
  </div>
</template>

<script>
export default {
  name: 'InputTextComponent',
  props: {
    modelValue: {
      type   : [String, Number],
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'text',
    }
  },

  emits: ['update:modelValue'],

  computed: {
    localValue: {
      get () {
        return this.modelValue
      },
      set (value) {
        this.$emit('update:modelValue', value)
      },
    },
  },
}
</script>
