import { createApp } from 'vue'
import App from './App.vue'
import './assets/styles/css/font.css'
import './assets/styles/scss/animation.scss'
import './assets/styles/scss/custom.scss'
import './index.css' // <-- Tailwind CSS
import router from './router'
import installI18n from './lang/index'
import Select2 from 'vue3-select2-component'
import 'vue-universal-modal/dist/index.css'
import VueUniversalModal from 'vue-universal-modal'
import Datepicker from 'vue3-datepicker';
import Autocomplete from 'vue3-autocomplete';
import 'vue3-autocomplete/dist/vue3-autocomplete.css'
import moshaToast from 'mosha-vue-toastify';
import 'mosha-vue-toastify/dist/style.css';
import VueClickAway from "vue3-click-away";
import PortalApi from './libs/portalApi'
import TravelApi from './libs/travelApi'
import VuePaginationTw from "vue-pagination-tw";
import "vue-pagination-tw/styles";
import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel'
import { Waypoint } from "vue-waypoint";
import globalFunction from '@/mixin/globalFunction';
import AmInput from '@/components/input/InputText'
import AmSelect from '@/components/input/Select'

const portalApi = new PortalApi()
const travelApi = new TravelApi()

const app = createApp(App)

app.config.globalProperties.$portalApi = portalApi.api()
app.config.globalProperties.$travelApi = travelApi.api()

installI18n(app)
app.use(router).mount('#app')
// eslint-disable-next-line vue/multi-word-component-names
app.component("Select2", Select2)
app.component("AutoComplete", Autocomplete)
app.use(VueUniversalModal, {
    teleportTarget: '#modals'
})
app.component("DatePicker", Datepicker)
app.use(moshaToast)
app.use(VueClickAway)
app.component("VuePaginationTw", VuePaginationTw);
app.component("VueCarousel", Carousel);
app.component("CarouselSlide", Slide);
app.component("CarouselPagination", Pagination);
app.component("CarouselNavigation", Navigation);
app.component("WaypointDiv", Waypoint);
app.component("AmInput", AmInput);
app.component("AmSelect", AmSelect);
app.mixin(globalFunction);

