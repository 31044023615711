import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '',
    name: 'main',
    component: () => import('@/layouts/Main.vue'),
    children: [
      {
        path: '/',
        name: 'Home',
        component: () => import('@/views/IndexView.vue')
      },
      {
        path: '/login',
        name: 'Login',
        component: () => import('@/views/LoginView.vue')
      },
      {
        path: '/logout',
        name: 'Logout',
        component: () => import('@/views/LogoutView.vue')
      },
      {
        path: '/forgot-password',
        name: 'Forgot Password',
        component: () => import('@/views/ForgotPasswordView.vue')
      },
      {
        path: '/register',
        name: 'Register',
        component: () => import('@/views/RegisterView.vue')
      },
      {
        path: '/register-success',
        name: 'Register Success',
        component: () => import('@/views/RegisterSuccessView.vue')
      },
      {
        path: '/reset-password/:code?',
        name: 'Reset Password',
        component: () => import('@/views/ResetPasswordView.vue')
      },
      {
        path: '/reset-password-success',
        name: 'Reset Password Success',
        component: () => import('@/views/ResetPasswordSuccessView.vue')
      },
      {
        path: '/verification-email/:code',
        name: 'Verification Email',
        component: () => import('@/views/VerificationEmailView.vue')
      },
      {
        path: '/verification-email-success',
        name: 'Verification Email Success',
        component: () => import('@/views/VerificationEmailSuccessView.vue')
      },

      //profile
      {
        path: '/profile',
        name: 'Profile',
        component: () => import('@/views/profile/ProfileView.vue')
      },
      {
        path: '/passengers',
        name: 'Passengers',
        component: () => import('@/views/profile/ProfileView.vue')
      },
      {
        path: '/wallet',
        name: 'Wallet',
        component: () => import('@/views/profile/WalletView.vue')
      },
      {
        path: '/wallet/how-to-top-up',
        name: 'Wallet How To Top Up',
        component: () => import('@/views/profile/WalletHowToTopUpView.vue')
      },

      //seat in couch
      {
        path: '/seat-in-coach',
        name: 'SIC Transfer',
        component: () => import('@/views/sic/HomeSIC')
      },
      {
        path: '/search-sic',
        name: 'Search SIC Transfer',
        component: () => import('@/views/sic/ResultSIC')
      },

      //express
      {
        path: '/express',
        name: 'Express Bus Transfer',
        component: () => import('@/views/express/HomeExpress')
      },
      {
        path: '/search-express',
        name: 'Search Express Bus Transfer',
        component: () => import('@/views/express/ResultExpress')
      },

      //disposal
      {
        path: '/disposal',
        name: 'Hourly Disposal',
        component: () => import('@/views/disposal/HomeDisposal')
      },
      {
        path: '/search-disposal',
        name: 'Search Hourly Disposal',
        component: () => import('@/views/disposal/ResultDisposal')
      },

      //private transfer
      {
        path: '/point-to-point',
        name: 'Private Transfer',
        component: () => import('@/views/ptp/HomePTP')
      },
      {
        path: '/search-point-to-point',
        name: 'Search Private Transfer',
        component: () => import('@/views/ptp/ResultPTP')
      },

      //tour packages
      {
        path: '/packages',
        name: 'Tour Transfer',
        component: () => import('@/views/packages/HomePackages')
      },
      {
        path: '/packages-detail/:Oid',
        name: 'Tour Transfer Detail',
        component: () => import('@/views/packages/DetailPackages')
      },
      {
        path: '/search-packages',
        name: 'Search Tour Transfer',
        component: () => import('@/views/packages/ResultPackages')
      },

      //transaction page
      {
        path: '/transaction-page/addon',
        name: 'Transaction Addon',
        component: () => import('@/views/transaction/AddOn')
      },
      {
        path: '/transaction-page/information',
        name: 'Transaction Information',
        component: () => import('@/views/transaction/InformationDetail')
      },
      {
        path: '/transaction-page/payment',
        name: 'Transaction Payment',
        component: () => import('@/views/transaction/PaymentTransaction')
      },
      {
        path: '/transaction-page/bank',
        name: 'Transaction Bank Transfer',
        component: () => import('@/views/transaction/BankTransferTransaction')
      },
      {
        path: '/transaction-page/success',
        name: 'Transaction Success',
        component: () => import('@/views/transaction/SuccessTransaction')
      },
      {
        path: '/transaction-page/failed',
        name: 'Transaction Failed',
        component: () => import('@/views/transaction/FailedTransaction')
      },
      //mamnage-booking
      {
        path: '/manage-booking',
        name: 'Manage Booking',
        component: () => import('@/views/ManageBookingView.vue')
      },

      //order
      {
        path: '/order',
        name: 'Order',
        component: () => import('@/views/order/OrderHistory.vue')
      },
      {
        path: '/order-detail/:Oid',
        name: 'Order Detail',
        component: () => import('@/views/order/OrderDetail.vue')
      },

      //detail trip
      {
        path: 'trip/:slug',
        name: 'Detail Trip',
        component: () => import('@/views/DetailTripView.vue')
      },

      //contact us
      {
        path: '/contact-us',
        name: 'Contact Us',
        component: () => import('@/views/ContactUsView.vue')
      },

      //faq
      {
        path: '/faq',
        name: 'FAQ',
        component: () => import('@/views/FAQView.vue')
      },

      //about us
      {
        path: '/about-us',
        name: 'About Us',
        component: () => import('@/views/AboutUsView.vue')
      },

      //Terms Conditions
      {
        path: '/terms-conditions',
        name: 'Terms & Conditions',
        component: () => import('@/views/TermsConditionsView.vue')
      },

      //Privacy Policy
      {
        path: '/privacy-policy',
        name: 'Privacy Policy',
        component: () => import('@/views/PrivacyPolicyView.vue')
      },

      //landing-page
      {
        path: '/coming-soon-apps',
        name: 'Coming Soon Apps',
        component: () => import('@/views/landing-page/ComingSoonApps.vue')
      },

      
    ]
  },
  // {
  //   path: '/passengers',
  //   name: 'passengers',
  //   component: () => ProfileView
  // },
  // {
  //   path: '/wallet',
  //   name: 'wallet',
  //   component: () => ProfileView
  // },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})


// eslint-disable-next-line no-unused-vars
router.beforeEach((to, from) => {
  document.title = "Ace Tours Transport - " + to.name;

  var query = "";
  for (var key of Object.keys(to.query)) {
    if (query == '') query += "?";
    if (query != '') query += "&";
    query+= `${key}=${to.query[key]}`
  }
  
  if ((to.path === "/profile" || to.path === "/wallet") && !localStorage.getItem('access_token')) {
    return '/' + query
  } else if (to.path === "/") return '/seat-in-coach' + query
  else return true
})

export default router
