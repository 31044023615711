import axios from 'axios'

class TravelApi {
    api () {
        const APP_ENV = process.env.NODE_ENV
        
        const travelApi = axios.create({
            baseURL: APP_ENV === 'development' ? process.env.VUE_APP_API_URL_TRAVEL_DEV : process.env.VUE_APP_API_URL_TRAVEL
        })

        travelApi.interceptors.request.use(async (config) => {
            config.params = {...config.params, system: process.env.VUE_APP_SYSTEM_CODE}
            var token = localStorage.getItem('access_token')
            config.headers.Authorization = (token ? 'Bearer ' + token : null)
            return config
        }, function (error) {
            console.log('request reject', error)
            return Promise.reject(error)
        })

        travelApi.interceptors.response.use((response) => {
            return response.data ? response.data : response
        }, (error) => {
            const { response = {} } = error
            const { data } = response
            return Promise.reject(data)
        })

        return travelApi
    }
}


export default TravelApi
