import axios from 'axios'

class PortalApi {
    api () {
        const portalApi = axios.create({
            baseURL: process.env.VUE_APP_API_URL_PORTAL
        })

        portalApi.interceptors.request.use(async (config) => {
            config.params = {...config.params, system: process.env.VUE_APP_SYSTEM_CODE}
            var token = localStorage.getItem('access_token')
            config.headers.Authorization = (token ? 'Bearer ' + token : null)
            return config
        }, function (error) {
            console.log('request reject', error)
            return Promise.reject(error)
        })

        portalApi.interceptors.response.use((response) => {
            return response.data ? response.data : response
        }, (error) => {
            const { response = {} } = error
            const { data } = response
            return Promise.reject(data)
        })

        return portalApi
    }
}


export default PortalApi
